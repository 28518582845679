// const ExpenseSort = ({ onSortChange }) => {
//     const handleSortChange = (e) => {
//         onSortChange(e.target.value);
//     };

//     return (
//         <select onChange={handleSortChange} className="border p-2">
//             <option value="all">All Time</option>
//             <option value="daily">Daily</option>
//             <option value="weekly">Weekly</option>
//             <option value="monthly">Monthly</option>
//             <option value="quarterly">Quarterly</option>
//             <option value="yearly">Yearly</option>
//         </select>
//     );
// };

// export default ExpenseSort;


import { useState } from 'react';

const ExpenseSort = ({ onSortChange }) => {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const handleSortChange = (e) => {
        const selectedSort = e.target.value;
        if (selectedSort === 'custom') {
            if (!startDate || !endDate) {
                alert('Please select both start and end dates.');
                return;
            }
            onSortChange({ sort: 'custom', start_date: startDate, end_date: endDate });
        } else {
            onSortChange({ sort: selectedSort });
        }
    };

    return (
        <div className="flex space-x-4">
            <select onChange={handleSortChange} className="border p-2">
                <option value="all">All Time</option>
                <option value="daily">Daily</option>
                <option value="weekly">Weekly</option>
                <option value="monthly">Monthly</option>
                <option value="quarterly">Quarterly</option>
                <option value="yearly">Yearly</option>
                <option value="custom">RANGE</option>
            </select>
            <input
                type="date"
                value={startDate}
                onChange={e => setStartDate(e.target.value)}
                className="border p-2"
                placeholder="Start Date"
            />
            <input
                type="date"
                value={endDate}
                onChange={e => setEndDate(e.target.value)}
                className="border p-2"
                placeholder="End Date"
            />
        </div>
    );
};

export default ExpenseSort;