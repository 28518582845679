import React, { useState, useEffect } from 'react';
import { AlertCircle, Check, PlusCircle, RefreshCw, Trash2 } from 'lucide-react';
import axiosClient from '../../axios';

const InventoryManagementApp = () => {
  // State management
  const [records, setRecords] = useState([]);
  const [measurements, setMeasurements] = useState([]);
  const [sheets, setSheets] = useState([]);
  const [cuttingTickets, setCuttingTickets] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [activeTab, setActiveTab] = useState('records');
  
  // New record form state
  const [newRecord, setNewRecord] = useState({
    store: {
      cutting_ticket_id: ''
    },
    storeItems: [{
      quantity: '',
      measurement_id: '',
      store_sheet_id: ''
    }]
  });

  // Fetch records from API
  const fetchRecords = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axiosClient.get('records/');
      if (response.status !== 200) {
        throw new Error('Failed to fetch records');
      }
      const data = await response.data;
      setRecords(data);
    } catch (err) {
      setError('Failed to fetch records: ' + err.message);
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  // Fetch measurements from API
  const fetchMeasurements = async () => {
    try {
      const response = await axiosClient.get('records/measurements');
      if (response.status !== 200) {
        throw new Error('Failed to fetch measurements');
      }
      const data = await response.data;
      setMeasurements(data);
    } catch (err) {
      console.error('Failed to fetch measurements:', err);
      setError('Failed to fetch measurements: ' + err.message);
    }
  };

  // Fetch sheets from API
  const fetchSheets = async () => {
    try {
      const response = await axiosClient.get('records/sheets');
      if (response.status !== 200) {
        throw new Error('Failed to fetch sheets');
      }
      const data = await response.data;
      setSheets(data);
    } catch (err) {
      console.error('Failed to fetch sheets:', err);
      setError('Failed to fetch sheets: ' + err.message);
    }
  };

  // Fetch cutting tickets from API
  const fetchCuttingTickets = async () => {
    try {
      const response = await axiosClient.get('records/cutting-tickets');
      if (response.status !== 200) {
        throw new Error('Failed to fetch cutting tickets');
      }
      const data = await response.data;
      setCuttingTickets(data);
    } catch (err) {
      console.error('Failed to fetch cutting tickets:', err);
      setError('Failed to fetch cutting tickets: ' + err.message);
    }
  };

  // Create new record via API
  const createRecord = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axiosClient.post('records/ADD', newRecord);
      
      if (response.status !== 201) {
        const errorData = await response.data;
        throw new Error(errorData.message || 'Failed to create record');
      }
      
      // Show success message and reset form
      setSuccess('Record created successfully!');
      setNewRecord({
        store: {
          cutting_ticket_id: ''
        },
        storeItems: [{
          quantity: '',
          measurement_id: '',
          store_sheet_id: ''
        }]
      });
      
      // Refresh records list
      fetchRecords();
      
      // Clear success message after 3 seconds
      setTimeout(() => setSuccess(null), 3000);
    } catch (err) {
      setError('Failed to create record: ' + err.message);
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  // Form handling for the parent object
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewRecord({
      ...newRecord,
      store: {
        ...newRecord.store,
        [name]: value
      }
    });
  };

  // Form handling for store items
  const handleItemChange = (e, index) => {
    const { name, value } = e.target;
    const newItems = [...newRecord.storeItems];
    newItems[index] = {
      ...newItems[index],
      [name]: value
    };
    setNewRecord({
      ...newRecord,
      storeItems: newItems
    });
  };

  const addItem = () => {
    setNewRecord({
      ...newRecord,
      storeItems: [
        ...newRecord.storeItems,
        {
          quantity: '',
          measurement_id: '',
          store_sheet_id: ''
        }
      ]
    });
  };

  const removeItem = (index) => {
    const newItems = [...newRecord.storeItems];
    newItems.splice(index, 1);
    setNewRecord({
      ...newRecord,
      storeItems: newItems
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    createRecord();
  };

  // Load data on component mount
  useEffect(() => {
    fetchRecords();
    fetchMeasurements();
    fetchSheets();
    fetchCuttingTickets();
  }, []);

  // Format date for display
  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  return (
    <div className="min-h-screen bg-gray-50 p-4">
      <div className="max-w-6xl mx-auto">
        <h1 className="text-3xl font-bold text-gray-800 mb-8">Inventory Management System</h1>
        
        {/* Tab Navigation */}
        <div className="flex mb-6 border-b border-gray-200">
          <button 
            onClick={() => setActiveTab('records')} 
            className={`px-4 py-2 font-medium text-sm ${activeTab === 'records' ? 'text-blue-600 border-b-2 border-blue-600' : 'text-gray-500'}`}
          >
            Records
          </button>
          <button 
            onClick={() => setActiveTab('create')} 
            className={`px-4 py-2 font-medium text-sm ${activeTab === 'create' ? 'text-blue-600 border-b-2 border-blue-600' : 'text-gray-500'}`}
          >
            Create Record
          </button>
          <button 
            onClick={() => setActiveTab('sheets')} 
            className={`px-4 py-2 font-medium text-sm ${activeTab === 'sheets' ? 'text-blue-600 border-b-2 border-blue-600' : 'text-gray-500'}`}
          >
            Sheets
          </button>
        </div>
        
        {/* Alert Messages */}
        {error && (
          <div className="mb-4 p-4 bg-red-50 border border-red-200 rounded-md flex items-center text-red-700">
            <AlertCircle className="w-5 h-5 mr-2" />
            <span>{error}</span>
          </div>
        )}
        
        {success && (
          <div className="mb-4 p-4 bg-green-50 border border-green-200 rounded-md flex items-center text-green-700">
            <Check className="w-5 h-5 mr-2" />
            <span>{success}</span>
          </div>
        )}
        
        {/* Records Table */}
        {activeTab === 'records' && (
          <div className="bg-white rounded-lg shadow">
            <div className="p-4 flex justify-between items-center border-b border-gray-200">
              <h2 className="text-lg font-medium text-gray-800">Records</h2>
              <button 
                onClick={fetchRecords}
                className="flex items-center text-sm px-3 py-1 rounded bg-gray-100 hover:bg-gray-200 text-gray-600"
              >
                <RefreshCw className="w-4 h-4 mr-1" />
                Refresh
              </button>
            </div>
            
            <div className="overflow-x-auto">
              {isLoading && activeTab === 'records' ? (
                <div className="p-8 text-center text-gray-500">Loading records...</div>
              ) : records.length === 0 ? (
                <div className="p-8 text-center text-gray-500">No records found.</div>
              ) : (
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Reference
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Cutting Ticket
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Created
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Items
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Details
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {records.map((record) => (
                      <tr key={record.id} className="hover:bg-gray-50">
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {record.reference}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {record.cutting_ticket?.reference_number}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {formatDate(record.createdAt)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {record.storeItems?.length || 0} item(s)
                        </td>
                        {/* <td className="px-6 py-4 whitespace-nowrap text-sm text-blue-600">
                          <button className="hover:text-blue-800">View</button>
                        </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        )}
        
        {/* Create Record Form */}
        {activeTab === 'create' && (
          <div className="bg-white rounded-lg shadow p-6">
            <h2 className="text-lg font-medium text-gray-800 mb-4">Create New Record</h2>
            
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Cutting Ticket
                </label>
                <select
                  name="cutting_ticket_id"
                  value={newRecord.store.cutting_ticket_id}
                  onChange={handleInputChange}
                  className="w-full p-2 border border-gray-300 rounded focus:ring-blue-500 focus:border-blue-500"
                  required
                >
                  <option value="">Select a cutting ticket...</option>
                  {cuttingTickets.map((ticket) => (
                    <option key={ticket.id} value={ticket.id}>
                      {ticket.reference_number}
                    </option>
                  ))}
                </select>
              </div>
              
              <div className="mb-4">
                <div className="flex justify-between items-center mb-2">
                  <label className="block text-sm font-medium text-gray-700">
                    Store Items
                  </label>
                  <button
                    type="button"
                    onClick={addItem}
                    className="flex items-center text-sm px-2 py-1 rounded bg-blue-50 hover:bg-blue-100 text-blue-600"
                  >
                    <PlusCircle className="w-4 h-4 mr-1" />
                    Add Item
                  </button>
                </div>
                
                {newRecord.storeItems.map((item, index) => (
                  <div key={index} className="flex flex-col md:flex-row gap-4 p-4 bg-gray-50 rounded mb-3">
                    <div className="flex-1">
                      <label className="block text-xs font-medium text-gray-500 mb-1">
                        Quantity
                      </label>
                      <input
                        type="number"
                        name="quantity"
                        value={item.quantity}
                        onChange={(e) => handleItemChange(e, index)}
                        className="w-full p-2 border border-gray-300 rounded"
                        required
                      />
                    </div>
                    
                    <div className="flex-1">
                      <label className="block text-xs font-medium text-gray-500 mb-1">
                        Measurement
                      </label>
                      <select
                        name="measurement_id"
                        value={item.measurement_id}
                        onChange={(e) => handleItemChange(e, index)}
                        className="w-full p-2 border border-gray-300 rounded"
                        required
                      >
                        <option value="">Select...</option>
                        {measurements.map((m) => (
                          <option key={m.id} value={m.id}>
                            {m.title} ({m.symbol})
                          </option>
                        ))}
                      </select>
                    </div>
                    
                    <div className="flex-1">
                      <label className="block text-xs font-medium text-gray-500 mb-1">
                        Sheet
                      </label>
                      <select
                        name="store_sheet_id"
                        value={item.store_sheet_id}
                        onChange={(e) => handleItemChange(e, index)}
                        className="w-full p-2 border border-gray-300 rounded"
                        required
                      >
                        <option value="">Select...</option>
                        {sheets.map((s) => (
                          <option 
                            key={s.id} 
                            value={s.id} 
                            disabled={s.balance <= 0}
                            className={s.balance <= 0 ? "text-gray-400" : ""}
                          >
                            {s.title} - ({s.balance})
                          </option>
                        ))}
                      </select>
                      {sheets.filter(s => s.balance > 0).length === 0 && (
                        <p className="text-xs text-red-500 mt-1">
                          No sheets available with balance. Please add more inventory first.
                        </p>
                      )}
                    </div>
                    
                    <div className="flex items-end">
                      {newRecord.storeItems.length > 1 && (
                        <button
                          type="button"
                          onClick={() => removeItem(index)}
                          className="p-2 text-red-500 hover:text-red-700"
                        >
                          <Trash2 className="w-4 h-4" />
                        </button>
                      )}
                    </div>
                  </div>
                ))}
              </div>
              
              <div className="mt-6">
                <button
                  type="submit"
                  disabled={isLoading || sheets.filter(s => s.balance > 0).length === 0}
                  className="w-full md:w-auto px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white font-medium rounded shadow-sm disabled:opacity-50"
                >
                  {isLoading ? 'Creating...' : 'Create Record'}
                </button>
                {sheets.filter(s => s.balance > 0).length === 0 && (
                  <p className="text-sm text-red-500 mt-2">
                    Cannot create record: No sheets available with balance.
                  </p>
                )}
              </div>
            </form>
          </div>
        )}
        
        {/* Sheets List */}
        {activeTab === 'sheets' && (
          <div className="bg-white rounded-lg shadow">
            <div className="p-4 border-b border-gray-200">
              <h2 className="text-lg font-medium text-gray-800">Available Sheets</h2>
            </div>
            
            {isLoading && activeTab === 'sheets' ? (
              <div className="p-8 text-center text-gray-500">Loading sheets...</div>
            ) : sheets.length === 0 ? (
              <div className="p-8 text-center text-gray-500">No sheets found.</div>
            ) : (
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-4">
                {sheets.map((sheet) => (
                  <div key={sheet.id} className="border border-gray-200 rounded-lg p-4 hover:shadow-md transition-shadow">
                    <h3 className="font-medium text-gray-800">{sheet.title}</h3>
                    <p className="text-sm text-gray-500 mb-2">{sheet.reference}</p>
                    <div className="flex justify-between items-center mt-2">
                      <span className="text-sm font-medium">Balance:</span>
                      <span className={`text-sm px-2 py-1 rounded ${
                        sheet.balance <= 0 ? 'bg-red-50 text-red-700' : 'bg-blue-50 text-blue-700'
                      }`}>
                        {sheet.balance}
                      </span>
                    </div>
                    <div className="mt-3">
                      <span className="text-xs text-gray-500">Last updated: {formatDate(sheet.updatedAt)}</span>
                    </div>
                    <div className="mt-2 pt-2 border-t border-gray-100">
                      <span className={`inline-block px-2 py-1 text-xs rounded ${
                        sheet.status ? 'bg-green-50 text-green-700' : 'bg-red-50 text-red-700'
                      }`}>
                        {sheet.status ? 'Active' : 'Inactive'}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default InventoryManagementApp;