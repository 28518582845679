import React, { useState, useEffect, useMemo, useCallback } from "react";
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from "recharts";
import axiosClient from "../../axios";

const ProductChart = ({ start, end }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [startDate, setStartDate] = useState(
    () => new Date(new Date().setMonth(new Date().getMonth() - 3))
  );
  const [endDate, setEndDate] = useState(() => new Date());

  // Custom tooltip component for better responsiveness
  const CustomTooltip = ({ active, payload, label }) => {
    if (!active || !payload || !payload.length) return null;

    // Sort products by value for better readability
    const sortedPayload = [...payload].sort((a, b) => b.value - a.value);

    return (
      <div className="bg-white p-4 shadow-lg rounded-lg border border-gray-200 max-w-[300px] max-h-[400px] overflow-y-auto">
        <p className="font-bold mb-2">{label}</p>
        <p className="text-sm text-gray-600 mb-2">
          Total Products: {data.find(item => item.month === label)?.totalProductForTheMonth || 0}
        </p>
        <div className="space-y-1">
          {sortedPayload.map((entry, index) => (
            entry.value > 0 && (
              <div key={index} className="flex justify-between items-center text-sm">
                <span style={{ color: entry.color }} className="font-medium truncate mr-2">
                  {entry.name}:
                </span>
                <span>{entry.value}</span>
              </div>
            )
          ))}
        </div>
      </div>
    );
  };

  // Memoized data transformation
  const transformData = useCallback((rawData) => {
    const productNames = new Set();
    rawData.forEach(monthData => {
      monthData.products.forEach(product => {
        if (product.total_count > 0) {
          productNames.add(product.product_name);
        }
      });
    });

    return rawData.map(monthData => {
      const transformedData = {
        month: monthData.month,
        totalProductForTheMonth: monthData.totalProductForTheMonth
      };

      monthData.products.forEach(product => {
        if (productNames.has(product.product_name)) {
          transformedData[product.product_name] = product.total_count;
        }
      });

      return transformedData;
    });
  }, []);

  // Fetch data with error handling
  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);

      const formattedStartDate = start ?? startDate.toISOString().slice(0, 7);
      const formattedEndDate = end ?? endDate.toISOString().slice(0, 7);

      const response = await axiosClient.get(
        `overview/analytics/product-count?startMonth=${formattedStartDate}&endMonth=${formattedEndDate}`
      );
      const transformedData = transformData(response.data);
      setData(transformedData);
    } catch (error) {
      setError(error.message);
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }, [start, end, startDate, endDate, transformData]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Memoize chart colors
  const getProductColor = useMemo(() => {
    const colorCache = new Map();
    return (product, index) => {
      if (!colorCache.has(product)) {
        colorCache.set(product, `hsl(${index * 137.5 % 360}, 70%, 50%)`);
      }
      return colorCache.get(product);
    };
  }, []);

  // Memoize product keys
  const productKeys = useMemo(() => {
    if (!data.length) return [];
    return Object.keys(data[0])
      .filter(key => key !== 'month' && key !== 'totalProductForTheMonth');
  }, [data]);

  const handleDateChange = useCallback((type, value) => {
    const date = new Date(value);
    if (type === 'start') {
      setStartDate(date);
    } else {
      setEndDate(date);
    }
  }, []);

  if (error) {
    return (
      <div className="p-4 bg-red-50 text-red-600 rounded-lg flex items-center gap-2">
        <span>⚠️ Error loading data: {error}</span>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-lg shadow-lg p-6">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-xl font-bold">Product Distribution</h2>
        <div className="text-sm text-gray-600">
          Total Products This Month: {data[data.length - 1]?.totalProductForTheMonth || 0}
        </div>
      </div>

      <div className="flex flex-wrap gap-4 mb-6">
        <div className="flex-1 min-w-[200px]">
          <label className="block text-sm font-medium mb-1">Start Date</label>
          <input
            type="date"
            value={startDate.toISOString().split("T")[0]}
            onChange={(e) => handleDateChange('start', e.target.value)}
            className="w-full border rounded-md p-2"
            max={endDate.toISOString().split("T")[0]}
          />
        </div>
        <div className="flex-1 min-w-[200px]">
          <label className="block text-sm font-medium mb-1">End Date</label>
          <input
            type="date"
            value={endDate.toISOString().split("T")[0]}
            onChange={(e) => handleDateChange('end', e.target.value)}
            className="w-full border rounded-md p-2"
            min={startDate.toISOString().split("T")[0]}
          />
        </div>
      </div>

      {loading ? (
        <div className="flex justify-center items-center h-[600px]">
          <div className="text-gray-600">Loading...</div>
        </div>
      ) : (
        <div className="h-[600px]">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              data={data}
              margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            >
              <XAxis dataKey="month" />
              <YAxis />
              <Tooltip content={<CustomTooltip />} />
              <Legend />
              {productKeys.map((product, index) => (
                <Bar
                  key={product}
                  dataKey={product}
                  fill={getProductColor(product, index)}
                  stackId="stack"
                />
              ))}
            </BarChart>
          </ResponsiveContainer>
        </div>
      )}
    </div>
  );
};

export default ProductChart;