import React, { useState, useEffect } from 'react';
import axios from 'axios';
import axiosClient from '../../axios';

const Measurement = () => {
  const [measurements, setMeasurements] = useState([]);
  const [newMeasurement, setNewMeasurement] = useState({
    symbol: '',
    title: ''
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  // Fetch all measurements
  const fetchMeasurements = async () => {
    setIsLoading(true);
    try {
      const response = await axiosClient('records/measurements');
      setMeasurements(response.data);
      setError(null);
    } catch (err) {
      setError('Failed to fetch measurements. Please try again.');
      console.error('Error fetching measurements:', err);
    } finally {
      setIsLoading(false);
    }
  };

  // Add a new measurement
  const addMeasurement = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await   axiosClient.post('records/add-measurement', newMeasurement);
      setSuccess('Measurement added successfully!');
      setNewMeasurement({ symbol: '', title: '' }); // Reset form
      fetchMeasurements(); // Refresh the list
      
      // Clear success message after 3 seconds
      setTimeout(() => {
        setSuccess(null);
      }, 3000);
    } catch (err) {
      setError('Failed to add measurement. Please try again.');
      console.error('Error adding measurement:', err);
    } finally {
      setIsLoading(false);
    }
  };

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewMeasurement(prev => ({
      ...prev,
      [name]: value
    }));
  };

  // Fetch measurements on component mount
  useEffect(() => {
    fetchMeasurements();
  }, []);

  return (
    <div className="container mx-auto px-4 py-8 max-w-4xl">
      <h1 className="text-2xl md:text-3xl font-bold mb-6 text-gray-800">Measurement Management</h1>
      
      {/* Add Measurement Form */}
      <div className="bg-white p-4 md:p-6 rounded-lg shadow-md mb-8">
        <h2 className="text-xl font-semibold mb-4 text-gray-700">Add New Measurement</h2>
        
        <form onSubmit={addMeasurement} className="space-y-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label htmlFor="title" className="block text-sm font-medium text-gray-700 mb-1">
                Title
              </label>
              <input
                type="text"
                id="title"
                name="title"
                value={newMeasurement.title}
                onChange={handleChange}
                required
                className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none"
                placeholder="e.g. Centimeter"
              />
            </div>
            
            <div>
              <label htmlFor="symbol" className="block text-sm font-medium text-gray-700 mb-1">
                Symbol
              </label>
              <input
                type="text"
                id="symbol"
                name="symbol"
                value={newMeasurement.symbol}
                onChange={handleChange}
                required
                className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none"
                placeholder="e.g. CM"
              />
            </div>
          </div>
          
          <div className="flex justify-end">
            <button
              type="submit"
              disabled={isLoading}
              className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 disabled:opacity-50"
            >
              {isLoading ? 'Adding...' : 'Add Measurement'}
            </button>
          </div>
        </form>
        
        {/* Success and Error Messages */}
        {success && (
          <div className="mt-4 p-3 bg-green-100 text-green-700 rounded-md">
            {success}
          </div>
        )}
        
        {error && (
          <div className="mt-4 p-3 bg-red-100 text-red-700 rounded-md">
            {error}
          </div>
        )}
      </div>
      
      {/* Measurements List */}
      <div className="bg-white p-4 md:p-6 rounded-lg shadow-md">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold text-gray-700">Measurements List</h2>
          <button
            onClick={fetchMeasurements}
            disabled={isLoading}
            className="px-3 py-1 text-sm bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400"
          >
            Refresh
          </button>
        </div>
        
        {isLoading ? (
          <div className="text-center py-4">Loading measurements...</div>
        ) : measurements.length > 0 ? (
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    ID
                  </th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Title
                  </th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Symbol
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {measurements.map((measurement) => (
                  <tr key={measurement.id} className="hover:bg-gray-50">
                    <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-500">
                      {measurement.id}
                    </td>
                    <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900">
                      {measurement.title}
                    </td>
                    <td className="px-4 py-3 whitespace-nowrap text-sm font-medium">
                      {measurement.symbol}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="text-center py-4 text-gray-500">No measurements found</div>
        )}
      </div>
    </div>
  );
};

export default Measurement;