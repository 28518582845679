import React, { useState, useEffect } from 'react';
import axiosClient from '../../axios';


const Sheet = () => {
  const [sheets, setSheets] = useState([]);
  const [title, setTitle] = useState('');
  const [code, setCode] = useState('');
  const [balance, setBalance] = useState('');
  const [selectedSheetId, setSelectedSheetId] = useState('');
  const [updateQuantity, setUpdateQuantity] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  
  // Modal states
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  
  const baseUrl = 'records';
  
  // Fetch all sheets
  const fetchSheets = async () => {
    setLoading(true);
    try {
      const response = await axiosClient.get(`${baseUrl}/sheets`);
      setSheets(response.data);
      setError('');
    } catch (err) {
      setError('Failed to fetch sheets. Please try again.');
      console.error('Error fetching sheets:', err);
    } finally {
      setLoading(false);
    }
  };
  
  // Create new sheet
  const createSheet = async (e) => {
    e.preventDefault();
    if (!title || !balance) {
      setError('Please provide both title and balance');
      return;
    }
    
    setLoading(true);
    try {
      const response = await axiosClient.post(`${baseUrl}/add-sheet`, {
        title,
        code,
        balance: Number(balance)
      });
      
      setSheets([...sheets, response.data]);
      setSuccess('Sheet created successfully!');
      setTitle('');
      setCode('');
      setBalance('');
      setError('');
      setShowCreateModal(false);
      
      // Clear success message after 3 seconds
      setTimeout(() => setSuccess(''), 3000);
    } catch (err) {
      setError('Failed to create sheet. Please try again.');
      console.error('Error creating sheet:', err);
    } finally {
      setLoading(false);
    }
  };
  
  // Update sheet
  const updateSheet = async (e) => {
    e.preventDefault();
    if (!selectedSheetId || !updateQuantity) {
      setError('Please provide both sheet ID and quantity');
      return;
    }
    
    setLoading(true);
    try {
      await axiosClient.post(`${baseUrl}/update-sheet`, {
        store_sheet_id: Number(selectedSheetId),
        quantity: Number(updateQuantity)
      });
      
      setSuccess('Sheet updated successfully!');
      setSelectedSheetId('');
      setUpdateQuantity('');
      setError('');
      setShowUpdateModal(false);
      
      // Refresh sheets data
      fetchSheets();
      
      // Clear success message after 3 seconds
      setTimeout(() => setSuccess(''), 3000);
    } catch (err) {
      setError('Failed to update sheet. Please try again.');
      console.error('Error updating sheet:', err);
    } finally {
      setLoading(false);
    }
  };
  
  // Open update modal with selected sheet
  const openUpdateModal = (sheetId) => {
    setSelectedSheetId(sheetId);
    setShowUpdateModal(true);
  };
  
  // Reset form and close modals
  const resetAndCloseModals = () => {
    setTitle('');
    setCode('');
    setBalance('');
    setSelectedSheetId('');
    setUpdateQuantity('');
    setError('');
    setShowCreateModal(false);
    setShowUpdateModal(false);
  };
  
  // Fetch sheets on component mount
  useEffect(() => {
    fetchSheets();
  }, []);
  
  // Format date
  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };
  
  return (
    <div className="m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl">
      <h1 className="text-2xl font-bold text-gray-800 mb-6">Sheet Management</h1>
      
      {/* Alert Messages */}
      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
          {error}
        </div>
      )}
      
      {success && (
        <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded mb-4">
          {success}
        </div>
      )}
      
      {/* Action Buttons */}
      <div className="flex flex-wrap gap-4 mb-6">
        <button
          onClick={() => setShowCreateModal(true)}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Create New Sheet
        </button>
        
        <button
          onClick={fetchSheets}
          disabled={loading}
          className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline disabled:opacity-50"
        >
          {loading ? 'Refreshing...' : 'Refresh Sheets'}
        </button>
      </div>
      
      {/* Sheets Table */}
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white">
          <thead>
            <tr className="bg-gray-100">
              <th className="py-2 px-4 border-b text-left">ID</th>
              <th className="py-2 px-4 border-b text-left">Title</th>
              <th className="py-2 px-4 border-b text-left">Code</th>
              <th className="py-2 px-4 border-b text-left">Reference</th>
              <th className="py-2 px-4 border-b text-left">Balance</th>
              <th className="py-2 px-4 border-b text-left">Status</th>
              <th className="py-2 px-4 border-b text-left">Created</th>
              <th className="py-2 px-4 border-b text-left">Logs</th>
              <th className="py-2 px-4 border-b text-left">Actions</th>
            </tr>
          </thead>
          <tbody>
            {sheets.length === 0 ? (
              <tr>
                <td colSpan="8" className="py-4 px-4 border-b text-center">
                  {loading ? 'Loading sheets...' : 'No sheets found.'}
                </td>
              </tr>
            ) : (
              sheets.map((sheet) => (
                <tr key={sheet.id} className="hover:bg-gray-50">
                  <td className="py-2 px-4 border-b">{sheet.id}</td>
                  <td className="py-2 px-4 border-b">{sheet.title}</td>
                  <td className="py-2 px-4 border-b">{sheet?.code ?? "-"}</td>
                  <td className="py-2 px-4 border-b font-mono text-sm">{sheet.reference}</td>
                  <td className="py-2 px-4 border-b">{sheet.balance}</td>
                  <td className="py-2 px-4 border-b">
                    <span className={`px-2 py-1 rounded-full text-xs ${sheet.status ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
                      {sheet.status ? 'Active' : 'Inactive'}
                    </span>
                  </td>
                  <td className="py-2 px-4 border-b text-sm">{formatDate(sheet.createdAt)}</td>
                  <td className="py-2 px-4 border-b">
                    <div className="text-sm">
                      {sheet.sheet_logs && sheet.sheet_logs.length > 0 ? (
                        <details>
                          <summary className="cursor-pointer text-blue-500">{sheet.sheet_logs.length} logs</summary>
                          <ul className="mt-2 pl-4">
                            {sheet.sheet_logs.map(log => (
                              <li key={log.id} className="mb-2">
                                <div className="text-xs text-gray-600">{formatDate(log.createdAt)}</div>
                                <div className="font-medium">Type: {log.type}</div>
                                <div className="font-medium">Quantity: {log.quantity}</div>
                              </li>
                            ))}
                          </ul>
                        </details>
                      ) : (
                        <span className="text-gray-500">No logs</span>
                      )}
                    </div>
                  </td>
                  <td className="py-2 px-4 border-b">
                    <button
                      onClick={() => openUpdateModal(sheet.id)}
                      className="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-3 rounded text-sm focus:outline-none focus:shadow-outline"
                    >
                      Update
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      
      {/* Create Sheet Modal */}
      {showCreateModal && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center z-50">
          <div className="relative bg-white rounded-lg shadow-xl mx-4 md:mx-auto max-w-md md:max-w-lg w-full">
            <div className="p-6">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-xl font-semibold text-gray-900">Create New Sheet</h3>
                <button
                  onClick={resetAndCloseModals}
                  className="text-gray-400 hover:text-gray-500 focus:outline-none"
                >
                  <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
              
              <form onSubmit={createSheet}>
                <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="title">
                    Title
                  </label>
                  <input
                    id="title"
                    type="text"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="Enter sheet title"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="title">
                    Code
                  </label>
                  <input
                    id="code"
                    type="text"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="Enter Code"
                  />
                </div>
                
                <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="balance">
                    Balance
                  </label>
                  <input
                    id="balance"
                    type="number"
                    value={balance}
                    onChange={(e) => setBalance(e.target.value)}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="Enter initial balance"
                  />
                </div>
                
                <div className="flex justify-end gap-4 mt-6">
                  <button
                    type="button"
                    onClick={resetAndCloseModals}
                    className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  >
                    Cancel
                  </button>
                  
                  <button
                    type="submit"
                    disabled={loading}
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline disabled:opacity-50"
                  >
                    {loading ? 'Creating...' : 'Create Sheet'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
      
      {/* Update Sheet Modal */}
      {showUpdateModal && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center z-50">
          <div className="relative bg-white rounded-lg shadow-xl mx-4 md:mx-auto max-w-md md:max-w-lg w-full">
            <div className="p-6">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-xl font-semibold text-gray-900">Update Sheet</h3>
                <button
                  onClick={resetAndCloseModals}
                  className="text-gray-400 hover:text-gray-500 focus:outline-none"
                >
                  <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
              
              <form onSubmit={updateSheet}>
                <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    Selected Sheet
                  </label>
                  {sheets.find(sheet => sheet.id === Number(selectedSheetId)) ? (
                    <div className="bg-gray-100 p-3 rounded">
                      <p className="font-semibold">{sheets.find(sheet => sheet.id === Number(selectedSheetId)).title}</p>
                      <p className="text-sm text-gray-600">Code: {sheets.find(sheet => sheet.id === Number(selectedSheetId))?.code}</p>
                      <p className="text-sm text-gray-600">Reference: {sheets.find(sheet => sheet.id === Number(selectedSheetId)).reference}</p>
                    </div>
                  ) : (
                    <p className="text-red-500">Sheet not found</p>
                  )}
                </div>
                
                <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="quantity">
                    Quantity
                  </label>
                  <input
                    id="quantity"
                    type="number"
                    value={updateQuantity}
                    onChange={(e) => setUpdateQuantity(e.target.value)}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="Enter quantity"
                  />
                </div>
                
                <div className="flex justify-end gap-4 mt-6">
                  <button
                    type="button"
                    onClick={resetAndCloseModals}
                    className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  >
                    Cancel
                  </button>
                  
                  <button
                    type="submit"
                    disabled={loading}
                    className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline disabled:opacity-50"
                  >
                    {loading ? 'Updating...' : 'Update Sheet'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Sheet;