import React, { useEffect, useState } from 'react';
import { LineChart, Line, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import axiosClient from '../../axios';


const CuttingticketChart = () => {
   
    const [completedTickets, setCompletedTickets] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axiosClient.get(`overview/analytics/ctks?start_date=2024-01-01&end_date=2025-01-31`);
      
                setCompletedTickets(response.data.data);
                
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    return (
        <div className="p-6 space-y-8">
            {/* Chart 1: Completed Cutting Tickets Month-over-Month */}
            <div className="bg-white shadow-md rounded-lg p-6">
                <h2 className="text-xl font-semibold mb-4">Completed Cutting Tickets (Month-over-Month)</h2>
                <ResponsiveContainer width="100%" height={400}>
                    <LineChart data={completedTickets}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="month" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Line type="monotone" dataKey="total_tickets" stroke="#8884d8" activeDot={{ r: 8 }} />
                    </LineChart>
                </ResponsiveContainer>
            </div>

            {/* Chart 2: Products Made Month-over-Month */}
            {/* <div className="bg-white shadow-md rounded-lg p-6">
                <h2 className="text-xl font-semibold mb-4">Products Made (Month-over-Month)</h2>
                <ResponsiveContainer width="100%" height={400}>
                    <BarChart data={productsMade}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="month" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="total_units" fill="#82ca9d" />
                    </BarChart>
                </ResponsiveContainer>
            </div> */}
        </div>
    );
};

export default CuttingticketChart;