import React from 'react'
import { Link, NavLink } from "react-router-dom";
import { SiShopware } from "react-icons/si";
import { MdOutlineCancel } from "react-icons/md";
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { analystLinks, attendantLinks, factoryLinks, links, shopLinks, RecordLinks } from '../data/dummy';
import { useStateContext } from '../contexts/ContextProvider';
import SuperPartials from './Partials/super_partials';
import FactoryPartials from './Partials/factory_partials';
import ShopPartials from './Partials/shop_partials';
import { useAuthContext } from '../hooks/useAuthContext';
import AttendantPartials from './Partials/attendant_partials';
import AnalystPartials from './Partials/analyst_partials';
import RecordPartials from './Partials/record_partial';
const Sidebar = () => {

  const { user } = useAuthContext();

  const { activeMenu, setActiveMenu, screenSize } = useStateContext();
  // const activeMenu = true;
  const handleCloseSidebar = () => {
    if (activeMenu && screenSize <= 900) {
      setActiveMenu(false);
    }
  }

  const activeLink = "flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg  text-md m-2 bg-light-gray m-2";
  const normalLink = " flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg  text-md  text-gray-700 dark:text-gray-200 dark:hover:text-black hover:bg-light-gray m-2"
  return (
    <div className='ml-3 h-screen md:overflow-hidden overflow-auto md:hover:overflow-auto pb-10'>
      {activeMenu && (
        <>
          <div className='flex justify-between items-center'>
            <Link
              to="/"
              onClick={handleCloseSidebar}
              className="items-center gap-3 ml-3 mt-4 flex text-xl font-extrabold tracking-tight dark:text-white text-slate-900"
            >
              <SiShopware /> <span>FH-ERP</span>
            </Link>
            <TooltipComponent content="Menu" position='BottomCenter'>
              <button
                type='button'
                onClick={() => setActiveMenu((prevActiveMenu) => !prevActiveMenu)}
                className="text-xl rounded-full p-3 hover:bg-light-gray mt-4 block md:hidden"
              >
                <MdOutlineCancel />
              </button>
            </TooltipComponent>
          </div>
          { user?.role === "999" ? (
            <>
              <SuperPartials
                links={links}
                NavLink={NavLink}
                handleCloseSidebar={handleCloseSidebar}
                activeLink={activeLink}
                normalLink={normalLink}
                key="superpartials"
              />
            </>
          ) : user?.role === "7467" ? (
            <ShopPartials
              links={shopLinks || links}
              NavLink={NavLink}
              handleCloseSidebar={handleCloseSidebar}
              activeLink={activeLink}
              normalLink={normalLink}
              key="factorypartials"
            />
          ) : user?.role === "805" ? (
            <AttendantPartials
              links={attendantLinks || links}
              NavLink={NavLink}
              handleCloseSidebar={handleCloseSidebar}
              activeLink={activeLink}
              normalLink={normalLink}
              key="factorypartials"
            />
          )
           : user?.role === "009" ? (
            <AnalystPartials
              links={analystLinks || links}
              NavLink={NavLink}
              handleCloseSidebar={handleCloseSidebar}
              activeLink={activeLink}
              normalLink={normalLink}
              key="analystspartials"
            />
          )  : user?.role === "444" ? (
            <RecordPartials
              links={RecordLinks || links}
              NavLink={NavLink}
              handleCloseSidebar={handleCloseSidebar}
              activeLink={activeLink}
              normalLink={normalLink}
              key="analystspartials"
            />
          )

            : (
              <FactoryPartials
                links={factoryLinks || links}
                NavLink={NavLink}
                handleCloseSidebar={handleCloseSidebar}
                activeLink={activeLink}
                normalLink={normalLink}
                key="factorypartials"
              />
            )}

        </>
      )}
    </div>

  )
}

export default Sidebar